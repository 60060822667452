'use client';
import { UserButton, useUser } from '@clerk/nextjs';
import { useRouter } from 'next/navigation';
import posthog from 'posthog-js';
import { useState } from 'react';

const header = [
  { name: 'Product', href: '/' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'Features', href: '/#features' },
  { name: 'Blog', href: '/blog' },
  { name: 'Templates', href: '/templates' },
];

export default function Header() {
  const router = useRouter();
  const user = useUser();

  return (
    <header className="fixed top-0 z-10 w-full bg-white/80 backdrop-blur-md">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between px-4 py-3 sm:py-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5 flex gap-2 items-center">
            <img className="h-7 w-7 text-lg rounded-lg" src="/icon.svg" alt="" />
            <span className="font-semibold leading-6 text-gray-900">Penno</span>
          </a>
        </div>
        <div className="hidden lg:flex">
          {header.map(item => (
            <a
              key={item.name}
              href={item.href}
              className="font-semibold text-sm leading-6 text-gray-900 hover:bg-gray-300 px-3 py-1 rounded-lg transition-colors duration-200 ease-in-out"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="lg:flex-1 lg:justify-end gap-2 items-center flex">
          {user.isSignedIn ? (
            <a
              href="/dashboard"
              className="text-sm font-semibold leading-6 text-white bg-primary rounded-lg px-3 py-1 cursor-pointer hover:bg-primary/80 transition-colors duration-200 ease-in-out"
            >
              Launch Penno
            </a>
          ) : (
            <>
              <div
                className="text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-lg px-3 py-1 cursor-pointer hover:bg-gray-300 transition-colors duration-200 ease-in-out"
                onClick={() => {
                  posthog.capture('sign_in_cta');
                  router.push('/sign-in');
                }}
              >
                Log In
              </div>
              <div
                className="text-sm font-semibold leading-6 text-white bg-primary rounded-lg px-3 py-1 cursor-pointer hover:bg-primary/80 transition-colors duration-200 ease-in-out"
                onClick={() => {
                  posthog.capture('sign_up_cta');
                  router.push('/sign-up');
                }}
              >
                Sign Up
              </div>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}
